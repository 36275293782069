.statistic-contacts {
  margin-top: 4.5rem;
  margin-bottom: 5rem;

  .content-wrapper {
    background-color: white;
    padding: 1.5rem 2.5rem 4.5rem;
    border-radius: 32px;
  }

  .title-wrapper {
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    align-items: center;
    display: flex;

    h2 {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }

    .icon-wrapper {
      margin-left: auto;
    }
  }

  .contact-wrapper {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #00824d;
    border-radius: 8px;
    padding: 2rem 2rem 0;

    .contact {
      margin-bottom: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .email,
    .phone {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    border-radius: 22px;
    margin-top: 3rem;
    margin-bottom: 3rem;

    .content-wrapper {
      padding: 1rem 1rem 2rem;
    }

    .title-wrapper {
      margin-top: 1rem;

      .icon-wrapper img {
        height: 33px;
        width: 33px;
      }
    }

    .contact-wrapper {
      padding: 1.25rem;
    }

    .contact {
      margin-bottom: 1.5rem;
    }
  }
}
